import Service from "../Service";
const resource = "PackingShipment/"

export default {

    save(obj, requestID) {
        return Service.post(resource + "Save", obj, {
            params: { requestID: requestID },
        });
    },

    pagination(obj, requestID) {
        return Service.post(resource + "pagination", obj, {
            params: { requestID: requestID }
        });
    },

    updatePositionPallet(obj, requestID) {
        return Service.post(resource + "updatePositionPallet", obj, {
            params: { requestID: requestID },
        });
    },

    GetTraceabilityPalelt(PltID, requestID) {
        return Service.post(resource + "GetTraceabilityPalelt", "", {
            params: { PltID: PltID, requestID: requestID },
        });
    },





};