<template>
	<div>
		<v-card>
			<v-container>
				<v-row justify="center">
					<b><h2 class="mt-3 ml-6">SENASA</h2></b>
                    
				</v-row>
                <v-row>
					<v-col class="pb-0">
						<v-divider></v-divider>
					</v-col>
				</v-row>
                
                <!-- <v-row justify="center" style="text-shadow: 0 0 black;">
					<v-col cols="3"><h4 class="mt-3 ml-6">Embarque: </h4></v-col>
                    <v-col cols="3"><h4 class="mt-3 ml-6">Fecha: </h4></v-col>
				</v-row> -->

				<v-row>
					<v-col class="pb-0">
						<v-divider></v-divider>
					</v-col>
				</v-row>

				<v-row>
                    <v-col lg="12" class="pt-0" cols="12">
                        <table border="0" style="width: 100%">
                            <tr>
                                <td style="width: 20%"><h4 class="mt-0 ml-0">Fecha</h4></td>
                                <td style="width: 30%"><h4 class="mt-0 ml-0">{{items[0].PrintDate}}</h4></td>
                                <td style="width: 20%"><h4 class="mt-0 ml-0">Nombre Empac.</h4></td>
                                <td style="width: 30%"><h4 class="mt-0 ml-0">SUNSHINE EXPORT S.A.C</h4></td>
                            </tr>
                            <tr>
                                <td style="width: 20%"><h4 class="mt-0 ml-0">Nombre Inspector</h4></td>
                                <td style="width: 30%"></td>
                                <td style="width: 20%"><h4 class="mt-0 ml-0">Exportador</h4></td>
                                <td style="width: 30%"></td>
                            </tr>
                            <tr>
                                <td style="width: 30%"><h4 class="mt-0 ml-0">N° Embarque</h4></td>
                                <td colspan="3"><h4 class="mt-0 ml-0">{{items[0].PlsNumberContainer}}</h4></td>
                            </tr>
                        </table>
                    </v-col>
					<v-col lg="12" class="pt-0" cols="12">
						<v-data-table
                            :items="items"
									item-key="RcfID"
									dense
									:headers="headers"
									:items-per-page="-1"
									disable-sort
                                    hide-default-footer
                        >

                        </v-data-table>
					</v-col>
				</v-row>
                 <v-row justify="center" style="text-shadow: 0 0 black;">
                    <v-col cols="8" align="right"><h3 >TOTAL GENERAL</h3></v-col>
                    <v-spacer></v-spacer>
                    <v-col cols="4">
                        <v-row>
                            <v-col cols="3" align="right"><h3 >{{JabaUsed}}</h3></v-col>
                            <v-col cols="3" align="right"><h3 >{{WeightTotal}}</h3></v-col>
                            <v-col cols="3" align="right"><h3 >{{QuantityBoxeExportable}}</h3></v-col>
                            <v-col cols="3" align="right"><h3 >{{WeightExportable}}</h3></v-col>
                        </v-row>
                    </v-col>
                    
				</v-row>
			</v-container>
		</v-card>
	</div>
</template>

<script>
	export default {
        props: {
            items: null
        },
		components: {},

		data () {
            return {
                WeightExportable: 0,
                JabaUsed: 0,
                WeightTotal: 0,
                QuantityBoxeExportable: 0,
                headers:[
                    {text:"Cod. Lugar LP", value: "FltCodSenasa", width: 100},
                    {text:"Guia Remision", value: "RcfNumberReferralGuide", width: 100},
                    {text:"Productor", value: "PrdCardName", width: 500},
                    {text:"Fruta", value: "TypeCultiveName", width: 100},
                    {text:"Variedad", value: "VrtName", width: 100},
                    {text:"Lote Prod.", value: "RcfID", width: 100},
                    {text:"Cant. Jabas Usadas", value: "JabaUsed", width: 100, align: "right"},
                    {text:"Peso (KG)", value: "WeightReference", width: 100, align: "right"},
                    {text:"Peso Total (KG)", value: "WeightTotal", width: 100, align: "right"},
                    {text:"Cant. Caja x LP", value: "QuantityBoxeExportable", width: 100, align: "right"},
                    {text:"Peso Total (KG)", value: "WeightExportable", width: 100, align: "right"},
                ]
            }
        },

		created() {

            console.log(this.items);

            if(this.items.length > 0) {
                this.items.forEach(element => {
                    this.WeightExportable += element.WeightExportable
                    this.JabaUsed += element.JabaUsed
                    this.WeightTotal += element.WeightTotal
                    this.QuantityBoxeExportable += element.QuantityBoxeExportable
                });
            }
            //this.itemsDetail = this.items

            /*  */

            
            /* console.log("itemssss", this.sumTotalBoxe); */
		}
	};
</script>
