<template>
	<div>
		<v-card>
			<v-container>
				<v-row>
					<h3 class="mt-3 ml-6">REPORTES</h3>
					<v-spacer> </v-spacer>
					<v-btn
						@click="$emit('close')"
						class="mt-3 mr-3"
						small
						fab
						color="error"
						><i style="font-size: 16px" class="fas fa-times"></i
					></v-btn>
				</v-row>

				<v-row>
					<v-col class="pb-0">
						<v-divider></v-divider>
					</v-col>
				</v-row>

				<v-row>
					<v-col lg="12" class="pt-0">
						<v-list>
							<v-list-item>
								<v-list-item-avatar>
									<v-btn
										color="success"
										fab
										@click="downloadExcelReportPrePacking()"
										><i
											class="fas fa-file-excel"
											style="font-size: 18px"
										></i
									></v-btn>
								</v-list-item-avatar>

								<v-list-item-content>
									<v-list-item-title
										><b
											><a style="color: black">
												REPORTE PRE-PACKING
											</a></b
										><a @click="viewPrePacking()"
											><i
												class="fas fa-eye"
												style="font-size: 18px"
											></i></a
									></v-list-item-title>
								</v-list-item-content>
							</v-list-item>

							<v-list-item>
								<v-list-item-avatar>
									<v-btn
										color="error"
										fab
										@click="upDialogReportSenasaParamas()"
										><i
											class="fas fa-file-pdf"
											style="font-size: 18px"
										></i
									></v-btn>
								</v-list-item-avatar>

								<v-list-item-content>
									<v-list-item-title
										><b
											><a style="color: black">
												REPORTE SENASA
											</a></b
										>
											<!-- <a @click="viewSenasa()"
											><i
												class="fas fa-eye"
												style="font-size: 18px"
											></i></a
											> -->
									</v-list-item-title>
								</v-list-item-content>
							</v-list-item>
						</v-list>
					</v-col>
				</v-row>
			</v-container>
		</v-card>

		<v-dialog
			v-model="dialogParamsReportSenasa"
			width="400"
		>
			<v-card>
				<s-toolbar
					label="SENASA 4.1"
					close
					@close="dialogParamsReportSenasa = false"
					download
					@download="downloadPdfReportSenasa()"
				></s-toolbar>
			
				<v-container>
					<v-row>
						<v-col>
							<s-text
								label="Pedido"
								v-model="paramsReportSenasa.OrdID"
							></s-text>
						</v-col>
						<v-col>
							<s-text
								label="N° Embarque"
								v-model="paramsReportSenasa.NumberShipment"
							></s-text>
						</v-col>
					</v-row>
				</v-container>
			</v-card>
		</v-dialog>

		<v-dialog v-model="openModelPrePack" v-if="openModelPrePack">
			<prf-packing-rpt-ov :items="itemsPrePack" @close="closePre()"></prf-packing-rpt-ov>
		</v-dialog>

		<v-dialog v-model="openModelSenasa" v-if="openModelSenasa">
			<prf-packing-rpt-senasa
				:items="itemsSenasa"
			></prf-packing-rpt-senasa>
		</v-dialog>
	</div>
</template>

<script>
	import _sPackingShipment from "../../../services/FreshProduction/PackingShipmentService";
	import _sPackingListShipment from "../../../services/FreshProduction/PackingListService";
	import PrfPackingRptOv from "./PrfPackingRptov.vue";
	import PrfPackingRptSenasa from "./PrfPackingRptSenasa.vue";
	export default {
		props: {
			item: null,
		},
		components: { PrfPackingRptOv, PrfPackingRptSenasa },
		data() {
			return {
				itemsPrePack: [],
				itemsSenasa: [],
				openModelPrePack: false,
				openModelSenasa: false,
				dialogParamsReportSenasa: false,
				paramsReportSenasa : {}
			};
		},

		created() {
		},

		methods: {

			closePre(){
				this.openModelPrePack = false
				this.$emit('close')
			},


			//***************** PRE-PACKING */
			viewPrePacking() {
				this.itemsPrePack = this.item;
				this.clickViewPrePack();
			},

			clickViewPrePack() {
				this.openModelPrePack = true;
			},

			downloadExcelReportPrePacking() {
				if(this.item.itemsDetail.length > 0) {
					_sPackingListShipment
					.downloadExcelReportPrePacking(
						this.item.itemsDetail,
						this.$fun.getUserID()
					)
					.then((r) => {
						if (r.status == 200) {
							this.$fun.downloadFile(
								r.data,
								this.$const.TypeFile.EXCEL,
								this.$fun.getUserID()
							);
						}
					});
				}

				
			},

			//**************** FIN PRE-PACKING */

			//***************** SENASA */

			viewSenasa() {
				if (this.item.OrdID !== null) {
					_sPackingListShipment
						.generatedPrePackSenasa(this.item.OrdID, this.$fun.getUserID())
						.then((resp) => {
							if (resp.status == 200) {

								if(resp.data.length >0){
									resp.data.forEach((element) => {
										element.PrintDate = this.$moment(element.PrintDate).format(this.$const.FormatDateDB);
									});
								}

								this.itemsSenasa = resp.data;
								console.log("itemsSenasa", this.itemsSenasa);

								this.clickViewSenasa();
							}
						});
				}
			},

			clickViewSenasa() {
				this.openModelSenasa = true;
			},

			upDialogReportSenasaParamas()
			{
				this.dialogParamsReportSenasa = true;
				this.paramsReportSenasa.OrdID = this.item.OrdID;
			},

			downloadPdfReportSenasa() {
				_sPackingListShipment
				.downloadPdfReportSenasa(
					this.paramsReportSenasa,
					this.$fun.getUserID()
				)
				.then((r) => {
					if (r.status == 200) {
						console.log("resp.data", r.data);
						this.$fun.downloadFile(
							r.data,
							this.$const.TypeFile.PDF,
							this.$fun.getUserID()
						);
					}
				});
				/* _sPackingListShipment
				.generatedPrePackSenasa(this.paramsReportSenasa, this.$fun.getUserID())
				.then((resp) => {
					if (resp.status == 200) {
						
						this.itemsSenasa = resp.data;

						_sPackingListShipment
							.downloadPdfReportSenasa(
								this.itemsSenasa,
								this.$fun.getUserID()
							)
							.then((r) => {
								if (r.status == 200) {
									console.log("resp.data", r.data);
									this.$fun.downloadFile(
										r.data,
										this.$const.TypeFile.EXCEL,
										this.$fun.getUserID()
									);
								}
							});
					}
				}); */
			},
		},
	};
</script>

<style>
</style>